<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from '../mixins/resize'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    name: {
      type: String,
      default: ''
    },
    chartTitle: {
      type: String,
      default: ''
    },
    xAxisData: {
      type: Array,
      default() {
        return []
      }
    },
    color: {
      type: Array,
      default() {
        return []
      }
    },
    yAxisData: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    className() {
      this.$nextTick(() => {
        this.initChart()
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      let colorList = ['#ffb74d', '#fff176', '#aed581', '#4db6ac', '#4fc3f7', '#7986cb', '#ba68c8', '#e57373']
      if (this.color.length > 0) {
        colorList = this.color
      }
      this.chart = echarts.init(this.$el, 'macarons')
      this.chart.setOption({
        title: {
          text: this.chartTitle,
          textStyle: {
            color: '#ffffff',
            fontWeight: 'bold',
            fontSize: 14
          }
        },
        color: ['#ffb74d', '#fff176', '#aed581', '#4db6ac', '#4fc3f7', '#7986cb', '#ba68c8', '#e57373'],
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
              color: ['#ffffff']
            }
          },
          axisLabel: {
            rotate: 45,
            textStyle: {
              color: '#fff' //坐标值得具体的颜色
            }
          },
          axisLine: {
            lineStyle: {
              color: '#fff' //左边线的颜色
            }
          },
          type: 'category',
          data: this.xAxisData
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
              color: ['#ffffff']
            }
          },
          axisLine: {
            lineStyle: {
              color: '#fff' //左边线的颜色
            }
          }
        },
        series: [
          {
            data: this.yAxisData,
            type: 'bar',
            barWidth: 20,
            itemStyle: {
              normal: {
                color: function(params) {
                  // build a color map as your need.
                  return colorList[params.dataIndex]
                },
                label: {
                  show: true,
                  position: 'top'
                }
              }
            }
          }
        ]
      })
    }
  }
}
</script>
<style lang="scss" scope>
.chart{
  margin-top:30px
}
</style>
