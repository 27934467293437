<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from '../mixins/resize'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '220px',
    },
    color: {
      type: Array,
      default() {
        return []
      },
    },
    chartTitle: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    chartData: {
      type: Array,
      default() {
        return []
      },
    },
    pieData: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    className() {
      this.$nextTick(() => {
        this.initChart()
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.chart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: ' {b}：{c} ',
        },
        color: ['#ffb74d', '#fff176', '#aed581', '#4db6ac', '#4fc3f7', '#7986cb', '#ba68c8', '#e57373'], //this.color,   //环形颜色
        graphic: {
          //图形中间文字
          type: 'text',
          left: 'center',
          top: 'center',
          style: {
            text: this.chartTitle, //this.name,
            textAlign: 'center',
            fill: '#fff',
            fontSize: 14,
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'], //饼图大小
            minAngle: 15, //最小角度
            startAngle: 270, //起始角度
            labelLine: {
              //图形外文字线
              normal: {
                length: 10,
                length2: 70,
              },
            },
            label: {
              normal: {
                // formatter: '{b}:{c}: ({d}%)',
                formatter: '{d}%  \n {b|{b}}', //图形外文字上下显示
                borderWidth: 10,
                borderRadius: 4,
                padding: [0, -70], //文字和图的边距
                rich: {
                  a: {
                    fontSize: 12,
                    lineHeight: 22,
                  },
                  b: {
                    //name 文字样式
                    fontSize: 12,
                    lineHeight: 22,
                  },
                  c: {
                    //value 文字样式
                    fontSize: 12,
                    lineHeight: 22,
                    align: 'center',
                  },
                },
              },
            },
            data: this.pieData,
          },
        ],
      })
    },
  },
}
</script>
<style lang="scss" scope>
.chart{
  margin-top:30px
}
</style>