<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from '../mixins/resize'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    name: {
      type: String,
      default: ''
    },
    chartTitle:{
      type: String,
      default: ''
    },
    xAxisData: {
      type: Array,
      default() {
        return []
      }
    },
    yAxisData: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    className() {
      this.$nextTick(() => {
        this.initChart()
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.chart.setOption({
        title: {
          text: this.name,
          textStyle: {
            color: '#ffffff',
            fontWeight: 'bold',
            fontSize: 14
          }
        },
        color:['#ffb74d','#fff176','#aed581','#4db6ac','#4fc3f7','#7986cb','#ba68c8','#e57373'],
        grid: {
          left: '4%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: ' rgba(23, 99, 143, 0.75)',
          padding: [5, 10],
          formatter(params) {
            let point = params[0];
            return point.name + ': ' + point.value
          }
        },
        calculable: true,
        xAxis: [
          {
            nameTextStyle: {
              color: '#ffffff',
              fontSize: 16,
              padding: [50, 0, 0, 10]
            },
            type: 'category',
            boundaryGap: false,
            data: this.xAxisData,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            offset: 10,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: ['#ffffff']

              }
            },
            axisLabel: {
              interval: 0,    //强制文字产生间隔
              rotate: 45,
              show: true,
              textStyle: {
                color: '#ffffff',
                fontSize: 12
              }
            },
            axisPointer: { //基准线
              lineStyle: {
                color: '#fffff'
              }
            }

          }
        ],
        yAxis: [
          {
            axisLabel: {
              show: true,
              textStyle: {
                color: '#ffffff',
                fontSize: 12
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            offset: 10,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: ['#ffffff']

              }
            }
          }
        ],
        series: [
          {
            name: '2019',
            type: 'line',
            smooth: true,
            symbolSize: 10,   //设定实心点的大小
            lineStyle: {
              color: '#0E9CFF' //改变折线颜色
            },
            lineWidth: 2,
            itemStyle: {
              normal: {
                areaStyle: { type: 'default' },
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: '#72CCFF' },
                    { offset: 0.5, color: 'rgba(114, 204, 255, 0.2' },
                    { offset: 1, color: 'rgba(114, 204, 255, 0.2' }
                  ]
                ),
                borderColor: '#0E9CFF',//拐点边框颜色
                borderWidth: 2//拐点边框大小
              }
            },
            data: this.yAxisData
          },
        ]
      })
    }
  }
}
</script>
<style lang="scss" scope>
.chart{
  margin-top:30px
}
</style>