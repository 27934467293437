<template>
  <div class="chart_container">
    <!-- 中心内容 -->
    <div class="conponent_main">
      <!-- 首页 -->
      <template>
        <div v-for="(item, index) in dataList" :key="index">
          <sz-mode :bg="'left_bg'" :dIndex="index" :position="item.position" :width="modeWidht" :height="item.position.indexOf('-') != '-1' ? modeHeightSmall : modeHeightLong" :icon="item.icon" :title="item.title" :goInfoTemp="item.enterable == '1' ? true : false">
            <template #index>
              <div class="zrxx_box flex_colum">
                <div v-for="(items, index) in item.detailList" :key="index">
                  <div class="zrxx_item flex_start flex_center_align" v-if="items.elementValue.elementType == 'link' || items.elementValue.elementType == 'text'">
                    <div :class="['zr_left', items.elementValue.value ? '' : 'title']" :style="{ width: items.elementValue.elementType == 'link' ? '100%' : '', color: items.color }" v-if="items.elementValue.key">
                      {{ items.elementValue.key }}
                    </div>
                    <div class="zr_right" v-if="items.elementValue.value" :style="{ maxWidth: items.elementValue.key ? '65%' : '100%' }">
                      {{ items.elementValue.value }}
                    </div>
                    <img class="jinru" src="@/assets/img/jinru.png" alt="" v-if="items.elementValue.elementType == 'link'" @click="goDetail(items)" />
                  </div>
                  <pie-chart height="89px" style="margin-top:0" v-bind="items.elementValue" v-if="items.elementValue.elementType == 'pie'"></pie-chart>
                  <line-chart v-bind="items.elementValue" v-if="items.elementValue.elementType == 'line'"></line-chart>
                  <more-line-chart v-bind="items.elementValue" v-if="items.elementValue.elementType == 'morelines'"></more-line-chart>
                  <colum-chart v-bind="items.elementValue" v-if="items.elementValue.elementType == 'bar'"></colum-chart>
                  <template v-if="items.elementValue.elementType == 'iframe'">
                  <div>
                    <div class="word ql-editor" v-html="items.elementValue.articleContent" style="white-space:pre-wrap;"></div>
                    </div>
                  </template>
                  <!-- 多选 -->
                  <template v-if="items.elementValue.elementType == 'checkBox' || items.elementValue.elementType == 'checkMode'">
                    <div class="checkbox flex_colum">
                      <div class="flex_start inner flex_center_align " style="width:auto" v-for="(it, index) in items.elementValue.checkList" :key="index">
                        <div class="flex_start linebox2" @click="changeMap(it, items.elementValue.elementType)">
                          <div class="line" :class="{ active: it.id == changerow.id }"></div>
                          <div class="title">{{ it.title }}</div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <!-- 监控 -->
                  <template v-if="items.elementValue.elementType == 'media'">
                    <div class="checkbox flex_colum">
                      <div class="flex_start inner flex_center_align " style="width:auto">
                        <div class="flex_start linebox2" @click="changeMap(items.elementValue)">
                          <div class="line" :class="{ active: items.elementValue.id == changerow.id }"></div>
                          <div class="title">{{ items.elementValue.title }}</div>
                        </div>
                        <img class="videoIcon" :src="require('@/assets/img/' + 'video.png')" alt="" @click="openVideo(items.elementValue)" />
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </sz-mode>
        </div>
      </template>
    </div>
    <!-- 底部 -->
    <EZUIKitJs ref="ezuik" />
    <cun-mode ref="cunMode"></cun-mode>
    <!-- 地图 -->
    <div id="mapcontainer" class="divwxMap" style="width: 100%; height:100%"></div>
    <t-footer :tabList="tabList" @tab="handlerTab"></t-footer>
  </div>
</template>

<script>
import SzMode from './SzMode.vue'
import PieChart from './chart/PieChart.vue'
import LineChart from './chart/LineChart.vue'
import MoreLineChart from './chart/MoreLineChart.vue'
import ColumChart from './chart/ColumChart.vue'
import EZUIKitJs from './EZUIKitJs.vue'
import bus from '../common/js/bus.js'
import { postLogin, getMenus, getOthers, getHomeDetail, getMaps, getResidents, getpqDetail,getAllPoints } from '@/api/api.js'
import { demoziran, demony, demogy, demofw, demoIndex } from '@/api/api1.js'
import { getID } from '@/utils/index.js'
import CunMode from './cunMode.vue'
import tFooter from './footer.vue'
export default {
  components: { SzMode, PieChart, LineChart, ColumChart, EZUIKitJs, CunMode, MoreLineChart, tFooter },
  data() {
    return {
      //登录
      loginShow: false,
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [{ required: true, message: '请输入账户', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      tabList: [],
      baseForm: {
        addres: '辽宁省朝阳市朝阳县波罗赤镇'
      },
      tianInfo: '',
      wxMap: null,
      modeWidht: '22.5%',
      modeHeightSmall: '48%',
      modeHeightLong: '100%',
      tabtype: 'index',
      indexList: [],
      //年
      year: '',
      num: 0,
      dataList: [],
      initData: {},
      temp: false,
      itemtype: '', //区分切换类型
      seen: false,
      pointData: []
    }
  },
  methods: {
    handlerTab(index) {
      this.$nextTick(() => {
        if (index != this.num) {
          this.num = index
          this.changeTab(index)
        }
      })
    },

    changeTab(index) {
      if (index == 0) {
        this.clearMarker()
        this.setCunMap()
        this.initBorder()
        this.changerow = ''
        this.dataList = this.indexList
      } else {
        this.dataList = []
        if (this.isLogin) {
          this.getDetatils(this.tabList[index].id)
        } else {
          this.demoziranData(index)
        }
      }
    },
    setCunMap(lx) {
      let data = []
      if (!this.isLogin) {
        data = [
          {
            name: '小平安村',
            mode: true,
            word:
              '位于天津市,蓟县,下营镇。毓秀钟灵,花团锦簇,天蓝水清,历史悠久平安村与周边地点：杏树岭，平津路，花园，北安，加水，长城公路，石炮沟，龙潭沟，黄崖关村，小平安，句河桥，黄崖关主要农产品：生姜、红椒、油桃村里单位：小平安村党支部、小平安村村长小平安村及周边其他地点或单位：天津乡音小居，大亮农家院，鑫佳山庄，连凤农家院，水岸山庄，瑞龙庄园，燕坡农家院，天津蓟县山水间农家院停车场',
            imgList: ['luzhangzi1', 'luzhangzi2'],
            lgt: '119.959', // 经度
            lat: '41.448' //纬度
          },
          {
            name: '前干涧村',
            mode: true,
            word: '前干涧村2021年8月，前干涧村入选第三批全国乡村旅游重点村名单。前干涧村附近有梨木台景区、下营郭家沟、八仙山自然保护区、天津蓟县九山顶、天津龙泉山游乐园等旅游景点，有州河鲤鱼、黄花山核桃、红花峪桑葚、天津板栗、桑梓西瓜等特产。',
            imgList: ['kangjiatun1', 'kangjiatun2'],
            lgt: '119.99', // 经度
            lat: '41.431' //纬度
          },
          {
            name: '大平安村',
            mode: true,
            word: '大平安村附近有梨木台景区、下营郭家沟、八仙山自然保护区、天津蓟县九山顶、天津龙泉山游乐园等旅游景点，有州河鲤鱼、黄花山核桃、红花峪桑葚、天津板栗、桑梓西瓜等特产',
            imgList: ['huajiadian1', 'huajiadian2'],
            lgt: '119.934', // 经度
            lat: '41.392' //纬度
          },
          {
            name: '石炮沟村',
            mode: true,
            word: '石炮沟村附近有梨木台景区、下营郭家沟、八仙山自然保护区、天津蓟县九山顶、天津龙泉山游乐园等旅游景点，有州河鲤鱼、黄花山核桃、红花峪桑葚、天津板栗、桑梓西瓜等特产',
            imgList: ['xiaosanjia1', 'xiaosanjia2'],
            lgt: '119.995', // 经度
            lat: '41.38' //纬度
          }
        ]

        this.setMapSetting(data)
        this.initBorder()
      } else {
        if (lx) {
          let list = []
          console.log(this.dataList)

          this.dataList.forEach(item => {
            if (item.detailList.length > 0) {
              item.detailList.forEach(its => {
                if (its.elementValue.elementType == 'media' && its.elementValue.lat) {
                  list.push(its)
                }
                if (its.elementValue.elementType == 'checkBox' && its.elementValue.checkList.length > 0) {
                  its.elementValue.checkList.map(itss => {
                    if (itss.list.length > 0) {
                      list = list.concat(itss.list)
                    }
                  })
                }
              })
            }
          })
          data = list
          console.log(data)

          this.setMapSetting(data)
          this.setRegion(data)
        } else {
          var mdata = []
          var pdata = []
          getMaps(this.baseForm.regionId).then(res => {
            if (res.code == '0') {
              if (res.model.length) {
                res.model.map(item => {
                  item.lgt = item.longitude
                  item.lat = item.latitude
                  item.name = item.regionName
                  if (item.introduction) item.mode = true
                  item.icon = 'icon'
                })
              }
              mdata = res.model
              this.setMapSetting(mdata)
              this.setRegion(mdata)
            }
          })
          //查询所有点
          getAllPoints().then(res=>{
            if(res.code==0){
                if(res.model.length){
                  res.model.map(item=>{
                    if(item.elementValue )item.elementValue = JSON.parse(item.elementValue)
                    if(item.elementValue.checkList.length > 0){
                                 item.elementValue.checkList.map(itss => {
                                    if (itss.list.length > 0) {
                                      pdata = pdata.concat(itss.list)
                                    }
                                  })
                    }
                  })
                  this.setMapSetting(mdata.concat(pdata))
                }
            }
          })
        
        }
      }
      // console.log(data)
    },

    initMap() {
      // vue项目需要先声明 T = window.T，不然后面无法获取到。
      let T = window.T
      this.wxMap = null
      let initMap = new T.Map('mapcontainer') // div的id
      // 传参中心点经纬度，以及放大程度，最小1，最大18
      initMap.centerAndZoom(new T.LngLat(Number(this.baseForm.longitude) || 119.97503, Number(this.baseForm.latitude) || 41.406052), 12)
      // initMap.centerAndZoom(new T.LngLat(119.97503, 41.406052), 12)

      initMap.setStyle('black')
      initMap.enableDrag()
      initMap.enableScrollWheelZoom()
      this.wxMap = initMap
      this.$nextTick(() => {
        this.setCunMap()
        this.initBorder()
      })
    },
    goLogin() {
      this.loginShow = true
    },
    //获取菜单
    getMenus() {
      let list = [{ tabName: '总览', value: 'index', id: '', active: false, icon: require('@/assets/img/tb1.png') }]
      this.tabList = list
      // this.indexList=[]
      //  this.dataList=[]
      getMenus({ regionId: this.baseForm.regionId }).then(res => {
        if (res.code == '0') {
          if (res.items && res.items.length) {
            res.items.map(i => {
              if (i.tabName == '自然') {
                i.icon = require('@/assets/img/tb5.png')
              }
              if (i.tabName == '农业') {
                i.icon = require('@/assets/img/tb2.png')
              }
              if (i.tabName == '服务业' || i.tabName == '商业') {
                i.icon = require('@/assets/img/tb4.png')
              }
              if (i.tabName == '工业') {
                i.icon = require('@/assets/img/tb3.png')
              }
            })

            let index = res.items.findIndex(value => value.tabName == '自然')
            if (index || index == 0) res.items.splice(index, 1)
            this.tabList = [...list, ...res.items]
            console.log(this.tabList)
          }
        }
      })
      //首页详情
      getHomeDetail({ regionId: this.baseForm.regionId }).then(res => {
        console.log(res)
        if (res.code == '0') {
          if (this.baseForm.regionId == '50') {
            //小平房
            let data = {
              enterable: 0,
              icon: '/resources/user/637/20230319_155857_666ac6013577/index1.png',
              id: 24,
              position: 'left-top',
              regionId: 50,
              stId: '',
              tabName: null,
              title: '小平房产业简介',
              detailList: [
                {
                  id: 288,
                  shsId: 8,
                  elementType: 'iframe',
                  elementName: '简介',
                  elementValue:
                    '{"elementType":"iframe","detailList":[],"typeName":"富文本","xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[],"urls":[],"elementName":"简介","articleContent":"<p class=\\"ql-align-center\\"><strong class=\\"ql-size-large\\"></strong></p><p> \\t小平房村被誉为“辽西第一村”的省级文明村――建平县万寿街道办事处小平房村，在建设生态村的过程中，按照社会主义新农村建设的总体要求，走出了一条“依靠资源强工业，依靠工业哺农业”的新农村建设路子，在科技兴村的建设中迈出了坚实的步伐。</p>"}'
                }
              ]
            }
            var index = res.model.findIndex(value => value.title == '自然信息')
            res.model[index] = data
          }
          this.dataList = this.filterArr(res.model)
          this.indexList = this.dataList
          // console.log()
          console.log(this.dataList)
        }
      })
      
    },
    filterArr(list) {
      let arr = list
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].detailList && arr[i].detailList.length) {
          arr[i].detailList.map(item => {
            if (item?.elementValue) {
              item.elementValue = JSON.parse(item.elementValue)
              if (item.elementValue.elementType == 'checkBox') {
                // 多选
                item.elementValue.checkList.forEach(el => {
                  el.id = getID()
                })
              }
              if (item.elementValue.elementType == 'media') {
                // 带有监控
                item.elementValue.list = []
                item.elementValue.list.push({
                  icon: 'dapeng',
                  lat: item.elementValue.lat,
                  lgt: item.elementValue.lgt,
                  name: item.elementValue.title,
                  articleContent: item.elementValue.articleContent
                })
              }
              if (item.elementValue.elementType == 'morelines') {
                //多折线
                item.elementValue.legend = []
                item.elementValue.yAxisData.map(it => {
                  item.elementValue.legend.push(it.name)
                  it.data = []
                  if (it.tagData.length) {
                    it.tagData.map(ii => {
                      if (ii?.num != undefined) {
                        it.data.push(ii.num)
                      }
                    })
                  }
                })
              }
            }
          })
        }
      }
      return arr
    },
    //菜单详情
    getDetatils(stid, parentId) {
      getOthers({ stId: stid, parentId: parentId ? parentId : '' }).then(res => {
        if (res.code == '0') {
          this.dataList = this.filterArr(res.model)
          this.$nextTick(() => {
            this.clearMarker()
            this.setCunMap('ls')
            this.initBorder()
            this.changerow = ''
          })
        }
      })
    },
    //demo数据
    demodata() {
      // 首页默认显示
      this.indexList = [
        {
          id: 10,
          regionId: 6,
          stId: null,
          title: '服务业',
          position: 'right-bottom',
          icon: '/prod/apinull',
          enterable: 0,
          tabName: null,
          positionName: '右下',
          detailList: [
            {
              id: 56,
              shsId: 10,
              elementType: 'text',
              elementName: '测试修改',
              elementValue: '{"elementType":"text","key":"主要行业","value":"蔬菜批发市场","typeName":"文字","detailList":[],"xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[],"urls":[],"elementName":"测试修改"}'
            },
            {
              id: 57,
              shsId: 10,
              elementType: 'text',
              elementName: null,
              elementValue: '{"elementType":"text","detailList":[],"key":"主要企业","value":"惠农蔬菜批发市场","typeName":"文字","xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[],"urls":[]}'
            },
            {
              id: 58,
              shsId: 10,
              elementType: 'pie',
              elementName: null,
              elementValue: '{"elementType":"pie","detailList":[],"typeName":"饼状图","xAxisData":[],"yAxisData":[],"pieData":[{"name":"吸收量","value":"15"},{"name":"排放量","value":"10"}],"checkList":[],"urls":[],"chartTitle":"碳指标"}'
            }
          ]
        },
        {
          id: 8,
          regionId: 6,
          stId: 3,
          title: '工业',
          position: 'right-top',
          icon: '/prod/api/resources/user/11/20220128_143857_2f86154f5298/icon3.png',
          enterable: 1,
          tabName: '工业',
          positionName: '右上',
          detailList: [
            {
              id: 13,
              shsId: 2,
              elementType: 'text',
              elementName: '主要行业',
              elementValue: '{"elementType":"text","key":"主要行业","value":"旅游业","typeName":"文字","xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[],"elementName":"主要行业"}'
            },
            {
              id: 14,
              shsId: 2,
              elementType: 'text',
              elementName: '总产值',
              elementValue: '{"elementType":"text","key":"总产值","value":"16亿元","typeName":"文字","xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[],"elementName":"总产值"}'
            },
            {
              id: 55,
              shsId: 2,
              elementType: 'pie',
              elementName: null,
              elementValue: '{"elementType":"pie","detailList":[],"typeName":"饼状图","xAxisData":[],"yAxisData":[],"pieData":[{"name":"排放量","value":"5"},{"name":"吸收量","value":"10"}],"checkList":[],"urls":[],"chartTitle":"碳指标"}'
            }
          ]
        },
        {
          id: 6,
          regionId: 6,
          stId: 1,
          title: '自然信息',
          position: 'left-top',
          icon: '/prod/api/resources/user/11/20220125_114823_f48918bed21e/icon1.png',
          enterable: 1,
          tabName: '自然',
          positionName: '左上',
          detailList: [
            {
              id: 46,
              shsId: 6,
              elementType: 'link',
              elementName: '简介',
              elementValue:
                '{"elementType":"link","detailList":[],"key":"赤霞镇介绍","typeName":"跳转","xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[],"urls":[],"elementName":"简介","articleContent":"<p>赤霞峪村是天津市市辖区蓟州区下营镇下辖的社区。城乡分类代码为220，为村庄。区划代码为120119104229，居民身份证号码前6位为120119。赤霞峪村与黄崖关村、小平安村、前干涧村、石炮沟村、常州村、大平安村、段庄村、北车道峪村、中营村、小东沟村、西大峪村、青山岭村、下营村、郭家沟村、东山村、白滩村、东于庄村、刘庄子村、团山子村、张家峪村、苦梨峪村、桑树庵村、石头营村、道古峪村、船仓峪村、古强峪村、小港村、寺沟村、太平沟村、高富庄村、营周庄村、西于庄村、官地村、拱峪村相邻。</p>"}'
            },
            {
              id: 47,
              shsId: 6,
              elementType: 'text',
              elementName: '行政区类别',
              elementValue: '{"elementType":"text","detailList":[],"key":"行政区类别","value":"村/镇","typeName":"文字","xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[],"urls":[],"elementName":"行政区类别"}'
            },
            {
              id: 48,
              shsId: 6,
              elementType: 'text',
              elementName: null,
              elementValue: '{"elementType":"text","detailList":[],"key":"区域面积","value":"1220 km²","typeName":"文字","xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[],"urls":[]}'
            },
            {
              id: 49,
              shsId: 6,
              elementType: 'text',
              elementName: null,
              elementValue: '{"elementType":"text","detailList":[],"key":"户籍人口","value":"22221人","typeName":"文字","xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[],"urls":[]}'
            },
            {
              id: 50,
              shsId: 6,
              elementType: 'text',
              elementName: null,
              elementValue: '{"elementType":"text","detailList":[],"key":"政府驻地","value":"16个行政村","typeName":"文字","xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[],"urls":[]}'
            }
          ]
        },
        {
          id: 2,
          regionId: 6,
          stId: 2,
          title: '农业',
          position: 'left-bottom',
          icon: '/prod/api/resources/user/11/20220207_144105_d633fcd8db0d/icon6.png',
          enterable: 1,
          tabName: '农业',
          positionName: '左下',
          detailList: [
            {
              id: 13,
              shsId: 2,
              elementType: 'text',
              elementName: '主要行业',
              elementValue: '{"elementType":"text","key":"主要行业","value":" 种植,养殖，高梁,玉米,大豆","typeName":"文字","xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[],"elementName":"主要行业"}'
            },
            {
              id: 14,
              shsId: 2,
              elementType: 'text',
              elementName: '总产值',
              elementValue: '{"elementType":"text","key":"总产值","value":"3.02亿元","typeName":"文字","xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[],"elementName":"总产值"}'
            },
            {
              id: 55,
              shsId: 2,
              elementType: 'pie',
              elementName: null,
              elementValue: '{"elementType":"pie","detailList":[],"typeName":"饼状图","xAxisData":[],"yAxisData":[],"pieData":[{"name":"排放量","value":"5"},{"name":"吸收量","value":"10"}],"checkList":[],"urls":[],"chartTitle":"碳指标"}'
            }
          ]
        }
      ]
      // demoIndex().then((res) => {
      //   if (res.code == '200') {
      //     this.indexList = this.filterArr(res.items)
      this.$nextTick(() => {
        this.dataList = this.filterArr(this.indexList)
        // debugger
      })
      //   }
      // })
    },
    //登录后初始化
    init() {
      this.tabList = []
      //查询tab页
      this.getMenus()
    },
    getPoints(){
      getAllPoints().then(res=>{
        console.log(res);
        
      })
    },
    demoInit() {
      // 默认导航栏

      this.tabList = [
        {
          name: '首页',
          value: 'index',
          tabName: '首页',
          id: '',
          active: false,
          icon: require('@/assets/img/tab1.png')
        }
      ]
      this.demodata()
    },

    getArrIndex(arr, obj) {
      let index = null
      let key = Object.keys(obj)[0]
      arr.every(function(value, i) {
        // debugger
        if (value[key] === obj[key]) {
          index = i
          return false
        }
        return true
      })
      return index
    },
    //详情
    goDetail(item) {
      this.$refs.cunMode.initData(item)
    },
    demoziranData(index) {
      console.log(index)
      //自然
      if (index == '1') {
        var arr = [
          {
            id: 18,
            regionId: 6,
            stId: 4,
            parentId: null,
            position: 'right',
            title: '社会事业',
            icon: null,
            enterable: 1,
            regionName: '波罗赤镇',
            tabName: '自然',
            detailList: [
              {
                id: 112,
                ssId: 3,
                elementType: 'text',
                elementName: null,
                elementValue: '{"elementType":"text","detailList":[],"key":"文化事业","typeName":"文字","xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[],"urls":[]}'
              },
              {
                id: 113,
                ssId: 3,
                elementType: 'checkBox',
                elementName: null,
                elementValue: '{"elementType":"checkBox","detailList":[],"typeName":"多选","xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[{"list":[{"name":"赤霞镇文化中心","lgt":"119.9","lat":"41.42"}],"title":"镇文化中心1个"}],"urls":[]}'
              },
              {
                id: 114,
                ssId: 3,
                elementType: 'text',
                elementName: null,
                elementValue: '{"elementType":"text","detailList":[],"key":"教育事业","typeName":"文字","xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[],"urls":[]}'
              },
              {
                id: 115,
                ssId: 3,
                elementType: 'text',
                elementName: null,
                elementValue: '{"elementType":"text","detailList":[],"value":"初中适龄人口入学率: 98%","typeName":"文字","xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[],"urls":[]}'
              },
              {
                id: 116,
                ssId: 3,
                elementType: 'text',
                elementName: null,
                elementValue: '{"elementType":"text","detailList":[],"value":"九年义务教育覆盖率: 99.9%","typeName":"文字","xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[],"urls":[]}'
              }
            ]
          },
          {
            id: 17,
            regionId: 6,
            stId: 4,
            parentId: null,
            position: 'left-top',
            title: '人口情况',
            icon: null,
            enterable: 0,
            regionName: '波罗赤镇',
            tabName: '自然',
            detailList: [
              {
                id: 117,
                ssId: 1,
                elementType: 'text',
                elementName: null,
                elementValue: '{"elementType":"text","detailList":[],"key":"户籍人口","value":"123025人","typeName":"文字","xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[],"urls":[]}'
              },
              {
                id: 118,
                ssId: 1,
                elementType: 'bar',
                elementName: null,
                elementValue: '{"elementType":"bar","detailList":[],"typeName":"柱状图","xAxisData":["小平安村","前干涧村","大平安村","石炮沟村"],"yAxisData":["12301","20354","15203","5420"],"pieData":[],"checkList":[],"urls":[],"chartTitle":"四个代表村人口统计"}'
              }
            ]
          },
          {
            id: 16,
            regionId: 6,
            stId: 4,
            parentId: null,
            position: 'left-bottom',
            title: '交通',
            icon: null,
            enterable: 0,
            regionName: '波罗赤镇',
            tabName: '自然',
            detailList: [
              {
                id: 119,
                ssId: 2,
                elementType: 'iframe',
                elementName: null,
                elementValue: '{"elementType":"iframe","detailList":[],"typeName":"富文本","xAxisData":[],"yAxisData":[],"pieData":[],"checkList":[],"urls":[],"articleContent":"<p>赤霞镇境内有301国道过境，乡级路15条，</p>"}'
              }
            ]
          }
        ]
        this.dataList = this.filterArr(arr)
      }
      // demoziran().then((res) => {
      //   this.dataList = this.filterArr(res.items)
      // })
    },
    getDetail() {
      getpqDetail({ villageId: this.baseForm.regionId }).then(res => {
        if (res.code == '0') {
          console.log(res.items)
          let data = ''
          if (res.items.length) {
            res.items.map(item => {
              item.lgt = item.longitude
              item.lat = item.latitude
              item.mode = true
              item.type = 'gridType'
              if (item.introduction) item.mode = true
              item.icon = 'icon'
            })
          }
          data = res.items
          this.setMapSetting(data)
          this.initBorder()
        }
      })
    },
    getallResident() {
      getResidents(this.baseForm.regionId).then(res => {
        if (res.code == '0') {
          console.log(res.items)
          this.$refs.cunMode.optDspLineList = res.items
        }
      })
    },
    changeMap(item, type) {
      console.log('item')
      console.log(item)
      if (type == 'checkMode') {
        item.checked = !item.checked
        let data = item.list[0]
        data.itemtype = type
        this.$refs.cunMode.initData(data)
      } else {
        item.checked = !item.checked
        if (item.checked) {
          this.clearMarker(item)
          this.changerow = item
          let data = item.point || item.list
          this.pointData = []
          this.setMapSetting(data)
          this.initBorder()
        } else {
          this.clearMarker()
          this.setCunMap('ls')
          this.initBorder()
          this.changerow = ''
        }
      }
    }
  },
  mounted() {
    this.baseForm = JSON.parse(sessionStorage.getItem('userInfo'))
    this.$nextTick(() => {
      this.initMap()
    })
    if (!this.isLogin) {
      this.demoInit()
    } else {
      this.init()
    }

    bus.$on('goInfo', (index, title) => {
      if (this.num == 0 && (this.dataList[index].stId || this.dataList[index].stId == 0)) {
        //首页跳页
        let num = ''
        if (this.isLogin) {
          console.log('stId:::' + this.dataList[index].stId)
          num = this.getArrIndex(this.tabList, { id: this.dataList[index].stId }) // this.dataList[index].stId
          this.getDetatils(this.dataList[index].stId, '')
        } else {
          num = this.getArrIndex(this.tabList, { tabName: title }) // this.dataList[index].stId
        }
        this.changeTab(num)
        this.num = num
      } else {
        if (this.isLogin) this.getDetatils('', this.dataList[index].id)
        return
      }
    })
  },
  computed: {
    isLogin() {
      let flag = false
      if (this.initData && this.initData != '') {
        flag = true
      } else {
        flag = false
      }
      return flag
    }
  },
  created() {
    this.year = this.$moment().format('YYYY-MM-DD hh:mm:ss')
    this.initData = sessionStorage.getItem('userInfo')
    this.baseForm = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {}
  }
}
</script>

<style lang="scss" scoped>
@import '../common/css/chart';
.chart_container {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #0b1c9d 0%, #02004d 17.9%, #02004d 100%);
  color: #fff;
}
#container {
  width: 100%;
  height: 100%;
  z-index: 0;
  box-shadow: inset 0px 0px 250px 0px rgb(17, 40, 255);
}
.chart-wrapper {
  padding: 16px 16px 0;
  margin-bottom: 32px;
}
/deep/ .tdt-label {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 8px;
  background-color: #515459e8 !important;
  border-color: rgb(116, 118, 121) !important;
}
.ql_start{
  font-size: 120px;
    font-family: 'impact';
    color: #50db6a;
    line-height: 0;}
</style>
