<template>
  <div class="container">
    <chart-index></chart-index>
  </div>
</template>

<script>
import ChartIndex from '@/components/ChartIndex'
export default {
  components: {
    ChartIndex
  }
}
</script>

<style></style>
